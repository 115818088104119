import axios from 'axios'
import * as storage from './storage'
//import utils from './utils'
import jwt_decode from "jwt-decode";
import services from '.';
import globals from '../globals';

const APIROOT = globals.REACT_APP_APIROOT_AUTO;
const APIADDRESS = globals.REACT_APP_APIADRESS;
const CancelToken = axios.CancelToken;
let cancel

axios.interceptors.request.use(async function (config) {
    // Do something before request is sent
    let newConfig = {}
    if (config) {
        newConfig = config
    }

    try {
        if (
            newConfig.url.startsWith(APIROOT)
            && !newConfig.url.endsWith('login')
            //&& !newConfig.url.endsWith('publico-detalhe')
        ) {
            //console.log('config', config)
            let tokenData = storage.get('authSistema')
            let captcha = storage.get('captcha');

            if (tokenData?.token) {
                let decoded = jwt_decode(tokenData.token)
                let isExpired = _isExpired(decoded.exp)

                if (isExpired) {
                    console.log('is expired', isExpired)
                    services.storage.clear()
                    window.location.href = '/'
                    //history.push(`/`)
                    // re logar user
                    // let retLogin = await logar()
                    // if (retLogin.data) {
                    //     tokenData =  storage.get('authAgenda')
                    //     decoded = jwt_decode(tokenData.token)
                    //     isExpired = _isExpired(decoded.exp)
                    // } else {
                    //     //useHistory.push(`/error`)
                    //     services.storage.clear()
                    //     throw new Error('Erro ao autenticar')
                    // }
                }

                newConfig.headers = {
                    ...newConfig.headers,
                    'Authorization': `Bearer ${tokenData.token}`,
                }
            }
            else {
                newConfig.headers = {
                    ...newConfig.headers,
                    captcha: captcha,
                };
            }

        }
        return newConfig;
    } catch (error) {
        //console.log('00000')
        throw error
    }
}, function (error) {
    //debugger
    //console.log('000001111')
    // Do something with request error
    return Promise.reject(error);
});

const _isExpired = (dataEx) => {
    let dataExformat = dataEx * 1000
    //console.log(Date.now() , dataExformat)
    if (Date.now() >= dataExformat) {
        return true;
    }
    return false
}

const _sendWithAxios = async (url, method, payload) => {
    try {
        const result = await axios({
            url: url,
            method: method,
            data: payload
        })
        if (url.startsWith(APIADDRESS)) return { data: result.data, fail: null }
        return { data: result.data.data, fail: null }
    } catch (error) {
        //console.log('ret error', error)
        const errors = _parseError(error);
        return { data: false, fail: true, errors }
    }
}
const _sendWithAxiosCancelPrev = async (url, method, payload) => {
    if (cancel != undefined) { cancel() }
    try {
        const result = await axios({
            url: url,
            method: method,
            data: payload,
            cancelToken: new CancelToken(function executor(c) { cancel = c })
        })
        return { data: result.data.data, fail: null, status: result.status }
    } catch (error) {
        //console.log('ret error', error)
        const errors = _parseError(error);
        return { data: false, fail: true, errors }
    }
}

const _sendWithAxiosBlob = async (url, method, payload) => {
    try {
        const result = await axios({
            url: url,
            method: method,
            responseType: 'blob',
            data: payload
        })
        return { data: result.data, fail: null }
    } catch (error) {
        //console.log('ret error', error)
        const errors = _parseError(error);
        return { data: false, fail: true, errors }
    }
}

const _parseError = (error) => {
    let errors = [];
    const { response, isAxiosError } = error;
    if (response && response.data) {
        const { data } = response;
        errors = data || [];
    } else if (isAxiosError) {
        errors.push({ message: 'Falha ao enviar dados ao servidor' });
    }
    return errors;
}

////// geral
export const logar = async (acessParams) => {
    return await _sendWithAxios(`${APIROOT}login`, 'POST', acessParams)
};

export const resetarSenha = async (email) => {
    return await _sendWithAxios(`${APIROOT}usuario/senha/resetar`, 'POST', email)
};

export const confirmarResetSenha = async (params) => {
    return await _sendWithAxios(`${APIROOT}usuario/senha/confirmar`, 'POST', params)
}

export const existeParceiroNaBase = async (params) => {
    return await _sendWithAxios(`${APIROOT}existe`, 'POST', params)
};

export const codigoParceiro = async (params) => {
    return await _sendWithAxios(`${APIROOT}existe/codigo`, 'POST', params)
};

export const searchCpf = async (params) => {
    return await _sendWithAxios(`${APIROOT}usuario/existe`, 'POST', params)
};

export const perfis = async () => {
    return await _sendWithAxios(`${APIROOT}perfis`, 'GET')
};

export const preCadastro = async (params) => {
    return await _sendWithAxios(`${APIROOT}usuario/precadastro`, 'POST', params)
};

export const enviarAuth = async (params) => {
    return await _sendWithAxios(`${APIROOT}usuario/precadastro`, 'POST', params)
};


////// cotatos
export const contatosEmAberto = async (page = 1, busca = '') => {
    let obj = {
        "page": page,
        "pageSize": 13,
        "query": busca
    }
    return await _sendWithAxiosCancelPrev(`${APIROOT}fale-conosco/em-aberto`, 'POST', obj)
}
export const contatosFinalizados = async (page = 1, busca = '') => {
    let obj = {
        "page": page,
        "pageSize": 10,
        "query": busca
    }
    return await _sendWithAxiosCancelPrev(`${APIROOT}fale-conosco/finalizados`, 'POST', obj)
}
export const contatoDetalhe = async (contatoId) => {
    return await _sendWithAxios(`${APIROOT}fale-conosco/${contatoId}`, 'GET')
}

export const getCatalogoLink = async () => {
    return await axios({
      url: `${APIROOT}/catalog`,
      method: 'GET',
    }).then((response) => response.data.message);
  };


export const contatoEnviarMsg = async (faleConoscoAssuntoId, msg) => {
    let obj = {
        "faleConoscoAssuntoId": faleConoscoAssuntoId,
        "mensagem": msg,
    }
    return await _sendWithAxios(`${APIROOT}fale-conosco/enviar-mensagem`, 'POST', obj)
}
export const contatoEncerrar = async (faleConoscoAssuntoId) => {
    return await _sendWithAxios(`${APIROOT}fale-conosco/${faleConoscoAssuntoId}/encerrar`, 'POST', {})
}
export const contatoCriar = async (descricao, mensagem) => {
    let obj = {
        "descricao": descricao,
        "mensagens": {
            "mensagem": mensagem
        },
    }
    return await _sendWithAxios(`${APIROOT}fale-conosco`, 'POST', obj)
}

////// administrador
export const obterAdminstradores = async (page, busca = '') => {
    let obj = {
        "page": page,
        "pageSize": 10,
        "query": busca
    }
    return await _sendWithAxiosCancelPrev(`${APIROOT}administrador/filtrar`, 'POST', obj)
}
export const adminstradorDetalhe = async (id) => {
    return await _sendWithAxios(`${APIROOT}administrador/${id}`, 'GET')
}

export const adminstradorCriar = async (admin) => {
    return await _sendWithAxios(`${APIROOT}administrador`, 'POST', admin)
}
export const adminstradorEditar = async (id, admin) => {
    return await _sendWithAxios(`${APIROOT}administrador/${id}`, 'PUT', admin)
}
export const adminstradorAtivarInativar = async (id, status) => {
    return await _sendWithAxios(`${APIROOT}usuario/${id}/${status}`, 'POST', {})
}
export const adminstradorExportar = async () => {
    return await _sendWithAxiosBlob(`${APIROOT}administrador/exportar`, 'GET')
}

/////// empresas
export const obterEmpresas = async (page, busca = '') => {
    let obj = {
        "page": page,
        "pageSize": 10,
        "query": busca
    }
    return await _sendWithAxiosCancelPrev(`${APIROOT}empresa/filtrar`, 'POST', obj)
}
export const obterRecrutadoresPorEmpresas = async (page, id) => {
    let obj = {
        "page": page,
        "pageSize": 10,
    }
    return await _sendWithAxiosCancelPrev(`${APIROOT}empresa/${id}/recrutadores`, 'POST', obj)
}
export const empresaAll = async () => {
    return await _sendWithAxios(`${APIROOT}empresa/lista-simplificada`, 'GET')
}
export const empresaDetalhe = async (id) => {
    return await _sendWithAxios(`${APIROOT}empresa/${id}`, 'GET')
}
export const empresaAtivarInativar = async (id, status) => {
    return await _sendWithAxios(`${APIROOT}empresa/${id}/${status}`, 'PUT', {})
}
export const empresaCriar = async (admin) => {
    return await _sendWithAxios(`${APIROOT}empresa`, 'POST', admin)
}
export const empresaEditar = async (id, admin) => {
    return await _sendWithAxios(`${APIROOT}empresa/${id}`, 'PUT', admin)
}
export const empresasExportar = async () => {
    return await _sendWithAxiosBlob(`${APIROOT}empresa/exportar`, 'GET')
}
export const empresasUploadImg = async (file) => {
    const data = new FormData()
    data.append("file", file)
    return await _sendWithAxios(`${APIROOT}upload/logo-empresa`, 'POST', data)
}

/////// recrutadores
export const obterRecrutadores = async (page, busca = '') => {
    let obj = {
        "page": page,
        "pageSize": 10,
        "query": busca
    }
    return await _sendWithAxiosCancelPrev(`${APIROOT}recrutador/filtrar`, 'POST', obj)
}
export const candidatosPorEmpresaBusca = async (page, busca = '') => {
    let obj = {
        "page": page,
        "pageSize": 10,
        "query": busca
    }
    return await _sendWithAxiosCancelPrev(`${APIROOT}candidato/filtrar-por-empresa`, 'POST', obj)
}
export const agendarCadastrando = async (objEnvio) => {
    return await _sendWithAxiosCancelPrev(`${APIROOT}candidato/cadastrar-me-candidatar`, 'POST', objEnvio)
}

export const recrutadorDetalhe = async (id) => {
    return await _sendWithAxios(`${APIROOT}recrutador/${id}`, 'GET')
}
export const reEnviarMail = async (agendaId) => {
    return await _sendWithAxios(`${APIROOT}recrutador/enviar-email/${agendaId}`, 'GET')
}
export const reagendarEntrevista = async (novaData, candidatoVagaId, agendaAnteriorId) => {
    let objEnvio = {
        "dataAgendamento": novaData,
        "candidatoVagaId": candidatoVagaId,
        "agendaAnteriorId": agendaAnteriorId
    }
    return await _sendWithAxios(`${APIROOT}recrutador/reagendar-entrevista`, 'POST', objEnvio)
}
export const reagendarEntrevistaCandidato = async (vagaId, data) => {
    let objEnvio = {
        "candidatoVagaId": vagaId,
        "agendas": { "dataAgendamento": data }
    }
    return await _sendWithAxios(`${APIROOT}candidato/agendar`, 'POST', objEnvio)
}


export const recrutadorExportar = async () => {
    return await _sendWithAxiosBlob(`${APIROOT}recrutador/exportar`, 'GET')
}
export const recrutadorCriar = async (recrutador) => {
    return await _sendWithAxios(`${APIROOT}recrutador`, 'POST', recrutador)
}
export const recrutadorEditar = async (id, recrutador) => {
    return await _sendWithAxios(`${APIROOT}recrutador/${id}`, 'PUT', recrutador)
}
export const recrutadorEditarMeusDados = async (recrutador) => {
    return await _sendWithAxios(`${APIROOT}recrutador/meu-cadastro`, 'PUT', recrutador)
}
export const recrutadorAtivarInativar = async (id, status) => {
    return await _sendWithAxios(`${APIROOT}recrutador/${id}/${status}`, 'PUT', {})
}
export const recrutadorAceitarCadastro = async (id) => {
    return await _sendWithAxios(`${APIROOT}recrutador/${id}/aceitar`, 'PUT', {})
}
export const recrutadorPreCadastro = async (recrutador) => {
    return await _sendWithAxios(`${APIROOT}recrutador/pre-cadastro`, 'POST', recrutador)
}
export const extrairCandidadosPorEmpresa = async () => {
    return await _sendWithAxiosBlob(`${APIROOT}candidato/exportar-por-empresa`, 'GET')
}
export const recrutadorAgenda = async (ano, mes) => {
    return await _sendWithAxios(`${APIROOT}recrutador/minha-agenda/ano/${ano}/mes/${mes}`, 'GET')
}
export const recrutadorAgendaDetalhe = async (data) => {
    // data formato 2021-07-31
    return await _sendWithAxios(`${APIROOT}recrutador/detalhe-agenda/${data}`, 'GET')
}


/////// candidatos
export const obterCandidatos = async (page, busca = '') => {
    let obj = {
        "page": page,
        "pageSize": 10,
        "query": busca
    }
    return await _sendWithAxiosCancelPrev(`${APIROOT}candidato/filtrar`, 'POST', obj)
}
export const candidatoMinhasVagas = async (page, busca = '') => {
    let obj = {
        "page": page,
        "pageSize": 10,
        "query": busca
    }
    return await _sendWithAxiosCancelPrev(`${APIROOT}candidato/minhas-vagas`, 'POST', obj)
}
export const candidatoExportar = async () => {
    return await _sendWithAxiosBlob(`${APIROOT}candidato/exportar`, 'GET')
}
export const candidatoDetalhe = async (id) => {
    return await _sendWithAxios(`${APIROOT}candidato/${id}`, 'GET')
}
export const candidatoDetalheVisaoRecrutador = async (id) => {
    return await _sendWithAxios(`${APIROOT}candidato/candidatura/${id}/visao-recrutador`, 'GET')
}
export const candidatoDetalheAgenda = async (id) => {
    return await _sendWithAxios(`${APIROOT}candidato/candidatura/${id}/detalhe-agenda`, 'GET')
}
export const candidatoCriar = async (candidato) => {
    return await _sendWithAxios(`${APIROOT}candidato/cadastro-admin`, 'POST', candidato)
}
export const candidatoEditar = async (id, candidato) => {
    return await _sendWithAxios(`${APIROOT}candidato/${id}`, 'PUT', candidato)
}
export const candidatoAtivarInativar = async (id, status) => {
    return await _sendWithAxios(`${APIROOT}candidato/${id}/${status}`, 'PUT', {})
}
export const obterCandidaturasByCandidato = async (page, idCandidato) => {
    let obj = {
        "page": page,
        "pageSize": 10
    }
    return await _sendWithAxiosCancelPrev(`${APIROOT}candidato/${idCandidato}/historico-candidaturas`, 'POST', obj)
}
export const candidatoDetalheCandidatura = async (candidatoVagaId) => {
    return await _sendWithAxios(`${APIROOT}candidato/candidatura/${candidatoVagaId}`, 'GET')
}
export const candidatoUploadCurriculo = async (file) => {
    const data = new FormData()
    data.append("file", file)
    return await _sendWithAxios(`${APIROOT}upload/curriculos-candidatos`, 'POST', data)
}
export const candidatoPreCadastro = async (candidato) => {
    return await _sendWithAxios(`${APIROOT}candidato`, 'POST', candidato)
}
export const candidatoEditarMeusDados = async (candidato) => {
    return await _sendWithAxios(`${APIROOT}candidato/meu-cadastro`, 'PUT', candidato)
}
export const salvarCandidatura = async (candidato, id) => {
    return await _sendWithAxios(`${APIROOT}candidato/candidatura/${id}`, 'PUT', candidato)
}



/////// endereços
export const enderecoEstados = async () => {
    return await _sendWithAxios(`${APIROOT}estado`, 'GET')
}
export const enderecoEndByCep = async (cep) => {
    let value = cep.replace(/\-/g, "")
    return await _sendWithAxios(`${APIADDRESS}cep/${value}`, 'GET')
}
export const enderecoCidades = async (ufId) => {
    return await _sendWithAxios(`${APIROOT}cidade/${ufId}`, 'GET')
}


////////////// vagas
export const obterVagas = async (page, busca = '') => {
    let obj = {
        "page": page,
        "pageSize": 10,
        "query": busca
    }
    return await _sendWithAxiosCancelPrev(`${APIROOT}vaga/filtrar`, 'POST', obj)
}
export const obterVagasBusca = async (page, busca = '') => {
    let obj = {
        "page": page,
        "pageSize": 10,
        "query": busca
    }
    return await _sendWithAxiosCancelPrev(`${APIROOT}vaga/publico-lista`, 'POST', obj)
}
export const obterVagasPorEmpresa = async (page, busca = '') => {
    let obj = {
        "page": page,
        "pageSize": 10,
        "query": busca
    }
    return await _sendWithAxiosCancelPrev(`${APIROOT}vaga/filtrar-por-empresa`, 'POST', obj)
}
export const vagasExportar = async () => {
    return await _sendWithAxiosBlob(`${APIROOT}vaga/exportar`, 'GET')
}
export const meCandidatar = async (vagaId, data) => {
    let objEnvio = {
        "vagaId": vagaId,
        "agendas": {
            "dataAgendamento": data
        }
    }
    return await _sendWithAxios(`${APIROOT}candidato/me-candidatar`, 'POST', objEnvio)
}


export const vagasDetalhe = async (id) => {
    return await _sendWithAxios(`${APIROOT}vaga/${id}`, 'GET')
}
export const vagasDisponivel = async (id) => {
    //return await _sendWithAxiosBlob(`${APIROOT}vaga/exportar`, 'GET')
    return await _sendWithAxios(`${APIROOT}vaga/${id}/publico-detalhe`, 'GET')
}
export const vagasEditar = async (id, vaga) => {
    return await _sendWithAxios(`${APIROOT}vaga/${id}`, 'PUT', vaga)
}
export const vagasCriar = async (vaga) => {
    return await _sendWithAxios(`${APIROOT}vaga`, 'POST', vaga)
}
export const vagasDetalheCandidatos = async (idVaga, page) => {
    let obj = {
        "page": page,
        "pageSize": 10,
    }
    return await _sendWithAxios(`${APIROOT}vaga/${idVaga}/candidatos`, 'POST', obj)
}

//////////// outros
export const tiposContratacao = async () => {
    return await _sendWithAxios(`${APIROOT}tipo-contratacao`, 'GET')
}
export const tiposAnuncio = async () => {
    return await _sendWithAxios(`${APIROOT}tipo-anuncio`, 'GET')
}
export const statusProcessoVaga = async () => {
    return await _sendWithAxios(`${APIROOT}status-processo-vaga`, 'GET')
}
export const statusProcessoVagaNova = async () => {
    return await _sendWithAxios(`${APIROOT}status-processo-vaga/nova-vaga`, 'GET')
}
export const statusProcessoCandidato = async () => {
    return await _sendWithAxios(`${APIROOT}status-processo-candidato`, 'GET')
}
export const modelosTrabalho = async () => {
    return await _sendWithAxios(`${APIROOT}modelo-trabalho`, 'GET')
}
export const idiomas = async () => {
    return await _sendWithAxios(`${APIROOT}idioma`, 'GET')
}
export const idiomaFluencias = async () => {
    return await _sendWithAxios(`${APIROOT}fluencia-idioma`, 'GET')
}
export const niveisHierarquicosVaga = async () => {
    return await _sendWithAxios(`${APIROOT}nivel-hierarquico-vaga`, 'GET')
}
export const getMeuCadastroRecrutador = async () => {
    return await _sendWithAxios(`${APIROOT}recrutador/meu-cadastro`, 'GET')
}
export const getMeuCadastroCandidato = async () => {
    return await _sendWithAxios(`${APIROOT}candidato/meu-cadastro`, 'GET')
}
export const getSugestaoPesquisaVaga = async (busca) => {
    return await _sendWithAxios(`${APIROOT}vaga/sugestao-pesquisa/${busca}`, 'GET')
}
export const buscarEmpresaPorCnpj = async (cnpj) => {
    return await _sendWithAxios(`${APIROOT}empresa/${cnpj}`, 'GET')
}
export const getRegulamentoAtivo = async () => {
    return await axios({ url: `${APIROOT}regulamentos/ativo`, method: 'GET' }).then(response => response.data);
}
export const buscarRegulamentoVigentePorUsuario = async (userId) => {
    return await _sendWithAxios(`${APIROOT}regulamentos/vigente/${userId}`, 'GET')
}
export const aceiteRegulamento = async (userId, regulamentoId) => {
    let obj = {
        "userId": userId,
        "RegulamentosId": [
            regulamentoId
        ]
    }
    return await _sendWithAxios(`${APIROOT}regulamentos/aceitar`, 'POST', obj)
}
export const buscarDataAceiteRegulamento = async (userId) => {
    return await _sendWithAxios(`${APIROOT}regulamentos/ativo/${userId}`, 'GET')
}