import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

import './styles.scss';

/////////////////////////////
// redux
import { useDispatch } from "react-redux"
import { 
    showMsgW as showMsgWAction,
    showMsgE as showMsgEAction,
    showMsgS as showMsgSAction,
} from '../../../store/actions/snackbarActions.js';

import services from '../../../services';

import HeaderPage from './HeaderPage';
import MeusDados from './MeusDados';

import { trackPromise } from 'react-promise-tracker';

import { useGetUser } from '../../../hooks/useGetUser';

const formCadastroInitialState = {
  recrutadorId: "",
  aceiteCadastro: false,
  bairro1: "",
  cargo: "",
  celular: "",
  cep1: "",
  cidadeId1: '',
  complemento1: "",
  cpf: "",
  disponibilidades: [],
  email: "",
  empresa: {},
  empresaId: '',
  logradouro1: "",
  nome: "",
  numero1: "",
  recrutadorId: "",
  telefone: "",
  ufId:'',
  senha: '',
  senhaConfirmar: ''
}

const initDisponibilidade = [
  { diaSemanaId: 0, manha: false, noite: false, tarde: false },
  { diaSemanaId: 1, manha: false, noite: false, tarde: false },
  { diaSemanaId: 2, manha: false, noite: false, tarde: false },
  { diaSemanaId: 3, manha: false, noite: false, tarde: false },
  { diaSemanaId: 4, manha: false, noite: false, tarde: false },
  { diaSemanaId: 5, manha: false, noite: false, tarde: false },
  { diaSemanaId: 6, manha: false, noite: false, tarde: false }
]


const MeuCadastroEmpresa = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const showMsgW = (msg) => dispatch(showMsgWAction(msg));
  const showMsgE = (msg) => dispatch(showMsgEAction(msg));
  const showMsgS = (msg) => dispatch(showMsgSAction(msg));
  const [reload, setReload] = useState(0);

  const user = useGetUser()

  const [formCadastro, setFormCadastro] = React.useState({...formCadastroInitialState});
  const [agenda, setAgenda] = useState([...initDisponibilidade]);
  const [indicarErrosCadastro, setIndicarErrosCadastro] = React.useState(false);


  const handleChangeForm = (value, campo) => {
    if (campo === 'endereco') {
      let updateForm = formCadastro
      updateForm.logradouro1 = value.logradouro
      updateForm.bairro1 = value.bairro
      updateForm.ufId = value.uf.ufId
      updateForm.cidadeId1 = value.cidade.cidadeId
      setFormCadastro(updateForm)
    } else {
      setFormCadastro(prevstate => ({...prevstate, [campo]: value }))
    }
  }

  const handleChangeAgenda = (value, campo, index) => {
    let uptAgenda = agenda
    uptAgenda[index][campo] = value
    setAgenda(uptAgenda)
    setReload(reload + 1)
  }

  const salvar = () => {
    if(validarForm('editar')) { 
      editar()
    } else {
      showMsgW('Informe os campos obrigatórios')
    }
  }
  
  const carregarCadastro = () => {
    trackPromise (
      services.api.getMeuCadastroRecrutador()
      .then(res => {
        if (!res.fail) {
          //setChamado(res.data)
          setFormCadastro(res.data)
          if (res.data.disponibilidades.length) {
            setAgenda(res.data.disponibilidades)
          }
        } else {
          showMsgE(res.errors ? res.errors.message : 'Erro')
        }
      }).catch(error => {
          showMsgE(error.errors.length ? error.errors[0].message : 'Error')
      })
    )
  }

  const editar = () => {
    console.log('formCadastro====', formCadastro)
    let objEnvio = {
      "recrutadorId": formCadastro.recrutadorId,
      "nome": formCadastro.nome,
      "cpf": services.mask.unMask(formCadastro.cpf),
      "email": formCadastro.email,
      "telefone": services.mask.unMask(formCadastro.telefone),
      "celular": services.mask.unMask(formCadastro.celular),
      // "empresaId": formCadastro.empresaId,
      "cep1": services.mask.unMask(formCadastro.cep1),
      "ufId": formCadastro.ufId,
      "cidadeId1": formCadastro.cidadeId1,
      "bairro1": formCadastro.bairro1,
      "logradouro1": formCadastro.logradouro1,
      "numero1": formCadastro.numero1,
      "complemento1": formCadastro.complemento1,
      "disponibilidades": agenda
    }
    if (formCadastro.senha) objEnvio.senha = formCadastro.senha
    trackPromise (
      services.api.recrutadorEditarMeusDados(objEnvio)
      .then(res => {
        if (res.data) {
          showMsgS('Cadastro editado com sucesso')

          let userUpdate = user

          if (!userUpdate.cadastroCompleto) {
            userUpdate.cadastroCompleto = true
            userUpdate.email = formCadastro.email
            userUpdate.nome = formCadastro.nome
            let udateauth = services.storage.get('authSistema')
            udateauth.profile = userUpdate
            services.storage.set('authSistema', udateauth)
            window.open(`${window.location.origin}/`, '_self')
          } else {
            history.push('/')
          }

        } else {
          console.log(res)
          showMsgE(res.errors ? res.errors.message : 'Erro')
        }
      }).catch(error => {
          showMsgE(error.errors.length ? error.errors[0].message : 'Error')
      })
    )
  }

  const validarForm = (type) => {
    let valid = true
    let msg = ''
    if(!formCadastro.nome) valid = false;
    if(formCadastro.cpf && !services.validate.valideCpf(formCadastro.cpf)) {
      valid = false;
      msg = 'CPF inválido';
    }
    if(formCadastro.email && !services.validate.valideMail(formCadastro.email)) {
      valid = false;
      msg = 'E-mail inválido';
    }
    
    // let tel = services.mask.unMask(formCadastro.telefone)
    // if(!tel) { 
    //   valid = false;
    // } else {
    //   if(tel && tel.length < 10) {
    //     msg = 'Informe um telefone válido'
    //     valid = false;
    //   }
    // }

    // let cel = services.mask.unMask(formCadastro.celular)
    // if(!cel) { 
    //   valid = false;
    // } else {
    //   if(cel && cel.length < 11) {
    //     msg = 'Informe um celular válido'
    //     valid = false;
    //   }
    // }
    // if(!formCadastro.empresaId) valid = false;

    // if(!formCadastro.cep1) valid = false;
    // if(!formCadastro.ufId) valid = false;
    // if(!formCadastro.cidadeId1) valid = false;
    // if(!formCadastro.bairro1) valid = false;
    // if(!formCadastro.logradouro1) valid = false;
    // if(!formCadastro.numero1) valid = false;

    if (type === 'criar') {
      if(!formCadastro.senha) valid = false;
      if(!formCadastro.senhaConfirmar) valid = false;
      if(formCadastro.senha && formCadastro.senhaConfirmar && 
        (formCadastro.senha !== formCadastro.senhaConfirmar)
      ) {
        valid = false;
        msg = 'Senha e Confirmar senha devem ser iguais'
      }
      if(formCadastro.senha && formCadastro.senha.length < 6) {
        valid = false;
        msg = 'Senha deve ter pelo menos 6 digitos';
      }
    }
    if (type === 'editar') {
      if(formCadastro.senha && formCadastro.senhaConfirmar && 
        (formCadastro.senha !== formCadastro.senhaConfirmar)
      ) {
        valid = false;
        msg = 'Senha e Confirmar senha devem ser iguais'
      }
      if(formCadastro.senha && formCadastro.senha.length < 6) {
        valid = false;
        msg = 'Senha deve ter pelo menos 6 digitos';
      }
    }
    debugger
    console.log('====', type)
    if (!valid) setIndicarErrosCadastro(true)
    if (msg) { 
      showMsgW(msg) 
    } else {
      if (!valid) showMsgW('Informe todos os campos obrigatórios') 
    }
    return valid
  }

  useEffect(() => {
    carregarCadastro()
  }, []);
  return (
    <>
      <HeaderPage />
 
      <MeusDados 
       formPage={formCadastro}
       action={salvar}
       changes={handleChangeForm}
       indicarErros={indicarErrosCadastro}
       agenda={agenda}
       changeAgenda={handleChangeAgenda}
       reload={reload}
      />
    </>
  );
  
}

export default MeuCadastroEmpresa;